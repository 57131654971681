import axios from '@/plugins/axios'

export default {

  async list (data = {}) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`roles?${params}`)
  },

  async listPermissions (data = {}) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`permissions?${params}`)
  },

  async get (id) {
    return await axios.get(`roles/${id}`)
  },

  async insert (data) {
    return await axios.post('roles', data)
  },

  async update (id, data) {
    return await axios.put(`roles/${id}`, data)
  },

  async delete (id) {
    return await axios.delete(`roles/${id}`)
  },

}
