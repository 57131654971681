<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">
      <v-row>
        <v-col>

        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-sheet elevation="1">
            <v-data-table
              disable-filtering
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="roles"
              :options.sync="pagination"
              :footer-props="{
                'items-per-page-options': [10, 20, 50]
              }"
              @update:options="loadRoles"
            >
              <template
                v-slot:[`item.actions`]="{ item }"
                class="text-right"
              >
                <v-btn
                  icon
                  small
                  @click="openDialog(item)"
                  :disabled="item.id === 1"
                >
                  <v-icon
                    v-text="'mdi-pencil'"
                    small
                  />
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>


    <dialog-role 
      v-model="dialogRole"
      :role-id="editId"
      :key="`${editId}`"
      @save="loadRoles"
    />


  </div>
</template>

<script>
  import rolesApi from '@/api/roles'

  export default {
    components: {
      DialogRole: () => import('@/components/dialog/roles/DialogRole'),
    },

    data () {
      return {
        loading: false,
        dialogRole: false,
        count: 0,
        editId: null,
        headers: [
          { text: '#', value: 'id' },
          { text: 'Nome', value: 'name' },
          { text: '', value: 'actions', align: 'right' },
        ],
        roles: [],
        pagination: {},
      }
    },

    methods: {

      async loadRoles () {
        try {
          this.loading = true

          const response = await rolesApi.list({
            limit: this.pagination.itemsPerPage,
            offset: (this.pagination.page * this.pagination.itemsPerPage) - this.pagination.itemsPerPage,
          })

          this.roles = response.data.roles
          this.count = response.data.count
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      openDialog (user = null) {
        this.editId = user ? user.id : null
        this.dialogRole = true
      },

    },

  }
</script>
